// constants
const SHOW_CATEGORIES = "category/SHOW_CATEGORIES";

const showCategories = (categories) => {
    return {
        type: SHOW_CATEGORIES,
        categories
    }
}


export const getCategories = () => async (dispatch) => {
    //NEW CHANGE DEPLOYED SUCCESSFULLY!
    const res = await fetch('/api/categories/');
    console.log("HIT", res)
    if (res.ok) {
        console.log("RES HIT")
        const data = await res.json();
        console.log("HIT RES", data)
        dispatch(showCategories(data))
    }
}


const initialState = { allCategories: null };

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CATEGORIES:
            return {
                ...state,
                allCategories: action.categories
            };
        default:
            return state;
    }
}


export default categoryReducer